import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBar,Box,Button,Card,CardContent,Container,CssBaseline,Grid,IconButton,Toolbar,Typography,} from '@mui/material';
import logo from '../../Assests/SmartCapital.png';
import herobg from '../../Assests/hero_bg.png';
import realdata from '../../Assests/realdata.png';
import group2 from '../../Assests/Group2.png';
import group3 from '../../Assests/group3.png';
import group4 from '../../Assests/group4.png';
import OurVision from '../../Assests/OurVision.png';
import OurMission from '../../Assests/OurMission.png';
import herocard from '../../Assests/herocard.png';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 700 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': { boxShadow: 'none' },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: { borderRadius: 16 },
      },
    },
  },
});


const SmartCapitalLanding = () => {

  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/signin'); 
  };

  const handleSignUp = () => {
    navigate('/signup'); 
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Navbar */}
        <AppBar position="fixed" color="transparent" elevation={0} sx={{ bgcolor: 'white' }}>
          <Container maxWidth="lg">
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  component="img"
                  src={logo}
                  alt="Smart Capital logo"
                  sx={{ width: { xs: 140, md: 200 }, height: 40 }}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="outlined" color="primary" onClick={handleSignIn}>
                  Sign In
                </Button>
                <Button variant="contained" color="primary" onClick={handleSignUp}>
                  Sign Up
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

          {/* Main Content */}
          <Box
            sx={{
              position: 'relative', 
              backgroundImage: `url(${herobg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundAttachment: 'fixed',
              py: 10,
            }}
          >
            <Container maxWidth="lg">
              <Typography
                variant="h1"
                align="center"
                sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, mb: 4 }}
              >
                Invest Smarter with<br />Smart Capital
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ px: 4, py: 1.5 }}
                  onClick={handleSignUp} 
                >
                  Get Started
                </Button>
              </Box>

              {/* Card Section */}
              <Box sx={{ position: 'relative', zIndex: 10 }}>  
                <Card
                  sx={{
                    position: 'absolute', 
                    top: '100%',  
                    left: '50%',
                    transform: 'translateX(-50%)',  
                    width: '80%',
                    height: '350px',  
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    p: 3,
                    backgroundImage: `url(${herocard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', 
                    borderRadius: 2,
                  }}
                >
                  {/* <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#e3f2fd',
                      color: 'primary.main',
                      '&:hover': { bgcolor: '#bbdefb' },
                      mb: 3,
                    }}
                  >
                    OPEN AN ACCOUNT
                  </Button> */}
                </Card>
              </Box>
            </Container>
          </Box>


          {/* Features Section */}
          <Box sx={{ bgcolor: '#f5f5f5', py: 8, mt: 38 }}>
            <Container maxWidth="lg">
              <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                Why Choose Smart Capital?
              </Typography>
              <Grid container spacing={4}>
                {[
                  {
                    title: 'Real-Time Data',
                    description: 'Stay updated with the latest stock market trends.',
                    image: realdata,
                  },
                  {
                    title: 'Advanced Analytics',
                    description: 'Make informed decisions with our powerful tools.',
                    image: group2,
                  },
                  {
                    title: 'User-Friendly Interface',
                    description: 'Easy to use platform for all your investment needs.',
                    image: group3,
                  },
                  {
                    title: 'Secure Transactions',
                    description: 'Your investments are safe with our high-tech security.',
                    image: group4,
                  },
                ].map((feature, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card sx={{
                      height: '100%',
                      backgroundColor: 'rgba(37, 75, 126, 0.7)', 
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}>
                      <CardContent>
                        <Typography 
                          variant="h5" 
                          sx={{ mb: 2, fontWeight: 500, color: '#1b2028' }} 
                        >
                          {feature.title}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          color="text.secondary" 
                          sx={{ mb: 3 }}
                        >
                          {feature.description}
                        </Typography>
                      </CardContent>
                      {feature.content ? feature.content : (
                        <Box
                          component="img"
                          src={feature.image}
                          alt={feature.title}
                          sx={{
                            width: 'calc(100% + 20px)', 
                            height: 'auto',
                            borderRadius: 2,
                            display: 'block',
                            marginLeft: '-10px', 
                            marginRight: '-10px',
                          }}
                        />
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>


          {/* Vision and Mission Section */}
          <Box sx={{ py: 8 }}>
            <Container maxWidth="lg">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <Box
                      component="img"
                      src={OurVision}
                      alt="Vision illustration"
                      sx={{ width: '50%', height: 300, objectFit: 'cover', borderRadius: 4 }}
                    />
                    <Box sx={{ width: '50%' }}>
                      <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>Our Vision</Typography>
                      <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
                        Our vision is to become a trusted advisor, intermediary, and partner to assist companies in seamlessly accessing financial markets and become a professional marketplace similar to global reputations.
                      </Typography>
                      {/* <Button
                        endIcon={<ArrowForwardIcon />}
                        sx={{ color: 'primary.main', p: 0, '&:hover': { bgcolor: 'transparent' } }}
                      >
                        See More
                      </Button> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <Box sx={{ width: '50%' }}>
                      <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>Our Mission</Typography>
                      <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
                        Providing excellent service from risk placement to settlement of services, ensuring that our clients get the best capital market terms. Maintain the profitability of each user with the aim of meeting the expectations of each user.
                      </Typography>
                      {/* <Button
                        endIcon={<ArrowForwardIcon />}
                        sx={{ color: 'primary.main', p: 0, '&:hover': { bgcolor: 'transparent' } }}
                      >
                        See More
                      </Button> */}
                    </Box>
                    <Box
                      component="img"
                      src={OurMission}
                      alt="Mission illustration"
                      sx={{ width: '50%', height: 300, objectFit: 'cover', borderRadius: 4 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          component="footer"
          sx={{
            bgcolor: 'primary.main',
            color: 'white',
            py: { xs: 3, sm: 5 },
            mt: 'auto',
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
                flexWrap: 'wrap', 
                gap: 2, 
              }}
            >
              <IconButton href="https://twitter.com" target="_blank" sx={{ color: 'white' }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://instagram.com" target="_blank" sx={{ color: 'white' }}>
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://facebook.com" target="_blank" sx={{ color: 'white' }}>
                <FacebookIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, 
                justifyContent: 'space-between',
                alignItems: { xs: 'center', sm: 'center' }, 
                mt: 3,
                pt: 3,
              }}
            >
              <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                © 2024 Smart Capital. All rights reserved.
              </Typography>
              
              <Box 
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'center', sm: 'flex-end' }, 
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <Typography variant="body2" component="span" sx={{ mr: { sm: 3 }, mb: { xs: 1, sm: 0 } }}>
                  Info@smartcapital.co.in
                </Typography>
                <Typography variant="body2" component="span">
                  +91 8516992847
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

    </ThemeProvider>
  );
};

export default SmartCapitalLanding;

