import { useState, useEffect, useContext } from 'react';
import { Box, List, Typography, Paper, CircularProgress, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FilterList, HourglassEmpty ,Edit } from '@mui/icons-material';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { ThemeContext } from '../../theme-context';
import Cookies from 'js-cookie';
import NoDataIllustrator from '../../Assests/order.png';
import config from '../../config';
import { useWebSocketData } from '../../Context/WebSocketContext';
import Swal from 'sweetalert2';


const OrderText = styled(Typography)(({ status, isDark }) => {
  let color;
  if (status === 'completed') {
    color = 'rgb(22, 163, 74)';
  } else if (status === 'pending') {
    color = 'rgb(255, 165, 0)';
  } else if (status === 'failed') {
    color = 'rgb(255, 0, 0)';
  } else if (status === 'waiting') {
    color = 'rgb(255, 165, 0)';
  } else {
    color = isDark ? '#ccc' : 'rgb(102, 102, 102)';
  }

  return {
    color: color,
    fontSize: '0.75rem',
    fontWeight: 500,
  };
});

export default function OrderHistory() {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';
  const { socketData } = useWebSocketData();

  const [orderHistory, setOrderHistory] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const token = Cookies.get('userToken');
        const userId = Cookies.get('userId');

        const response = await fetch(`${config.apiBaseUrl}/order/getOrders`, {
          method: 'GET',
          headers: {
            'x-access-token': token,
            'user-id': userId,
          },
        });

        const data = await response.json();
        if (data?.orderData) {
          setOrderHistory(data.orderData);
          setFilteredOrders(data.orderData);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, []);

  useEffect(() => {
    if (filterStatus === 'all') {
      setFilteredOrders(orderHistory);
    } else {
      setFilteredOrders(orderHistory.filter(order => order.status === filterStatus));
    }
  }, [filterStatus, orderHistory]);

  const formattedPrice = (price) => price.toFixed(2);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setQuantity(order.quantity);
    setPrice(order.price);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
    setQuantity('');
    setPrice('');
  };

  const handleUpdateOrder = async () => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');

    try {
      const response = await fetch(`${config.apiBaseUrl}/order/modifyOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          userId,
          orderId: selectedOrder.orderId,
          quantity,
          price,
        }),
      });

      const data = await response.json();

      if (data?.success) {
        Swal.fire({
          icon: 'success',
          title: 'Order Updated',
          text: data?.message || 'Your order has been successfully updated.',
        });
        handleCloseModal(); 
      } else {
         handleCloseModal(); 
        Swal.fire({
          icon: 'warning',
          title: 'Order Updated',
          text: data?.message || 'Your order has been successfully updated.',
        });
      }
    } catch (error) {
      handleCloseModal();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update the order. Please try again.',
      });
    }
  };

  const cancelOrder = async (orderId) => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');

    try {
      const response = await fetch(`${config.apiBaseUrl}/order/cancelOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          userId,
          orderId,
        }),
      });

      const data = await response.json();

      if (data?.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Order Canceled',
          text: data?.message || 'Your order has been successfully canceled.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data?.message || 'There was an error canceling the order.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to cancel the order. Please try again.',
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '600px',
          backgroundColor: isDark ? '#121212' : '#fff',
        }}
      >
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '100%', margin: '0 auto', p: 2, minHeight: '600px', backgroundColor: isDark ? '#121212' : '#fff' }}>
      {orderHistory.length === 0 ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <img src={NoDataIllustrator} alt="No Data" style={{ width: '400px', height: 'auto' }} />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1.5, fontSize: '0.875rem', fontWeight: 400, color: isDark ? '#fff' : '#333' }}>
              Order History ({filteredOrders.length})
            </Typography>

            {/* Filter Button */}
            <IconButton
              sx={{
                color: 'rgb(0, 102, 255)',
                fontSize: '1.25rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.8,
                },
              }}
              onClick={() => setFilterStatus(filterStatus === 'waiting' ? 'all' : 'waiting')}
            >
              {filterStatus === 'waiting' ? <FilterList /> : <HourglassEmpty />}
            </IconButton>
          </Box>

          <List sx={{ p: 0 }}>
            {filteredOrders.map((order, index) => (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                  borderRadius: 1.5,
                  mb: 1.5,
                  p: 2,
                  backgroundColor: isDark ? '#333' : '#fff',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 500, color: order.transactionType === 'buy' ? 'rgb(22, 163, 74)' : 'rgb(255, 0, 0)' }}>
                    {order.symbol}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <OrderText status={order.status} isDark={isDark}>
                      {order.status}
                    </OrderText>
                  </Box>
                </Box>

                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 1, '& .MuiTypography-root': { fontSize: '0.875rem', lineHeight: 1.5, color: isDark ? '#ccc' : 'rgb(102, 102, 102)' } }}>
                  <Box>
                    <Typography>Order ID: {order.orderId}</Typography>
                    <Typography>Price: ₹{order.price}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography>Quantity: {order.quantity}</Typography>
                    <Typography sx={{ color: isDark ? '#ccc' : 'rgb(102, 102, 102)' }}>
                      Date: {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>
                    {order.status === 'waiting' && socketData[order.instrument_token] && (
                      <Typography sx={{ color: 'rgb(0, 102, 255)' }}>
                        (Mkt. Price) ₹{socketData[order.instrument_token]?.last_price ? formattedPrice(socketData[order.instrument_token].last_price) : '--'}
                      </Typography>
                    )}
                    {order.status === 'waiting' && (
                      <IconButton
                        sx={{ color: 'rgb(0, 102, 255)', fontSize: '1.25rem', ml: 1 }}
                        onClick={() => handleOpenModal(order)}
                      >
                        <Edit />
                      </IconButton>
                      
                    )}
                    {order.status === 'waiting' && (
                      <IconButton
                        sx={{ color: 'rgb(255, 0, 0)', fontSize: '1.25rem', ml: 1 }}
                        onClick={() => cancelOrder(order.orderId)}
                      >
                        <DoDisturbOnIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Paper>
            ))}
          </List>
        </>
      )}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Modify Order</DialogTitle>
        <DialogContent>
          <TextField
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateOrder} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
