import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Skeleton, Grid, CardContent, TextField } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import { useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import { ArrowBack } from '@mui/icons-material';
import { useWebSocketData } from "../../Context/WebSocketContext";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const getCurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};


const OptionDisplay = () => {
  const { theme } = useContext(ThemeContext);
  const [selectedDate, setSelectedDate] = useState(getCurrentDate);
  const [loading, setLoading] = useState(false);
  const [expiryDates, setExpiryDates] = useState([]);
  const [optionsData, setOptionsData] = useState({ CE: [], PE: [] });
  const [selectedInstrumentToken, setSelectedInstrumentToken] = useState(null);
  const [clickPrice, setClickPrice] = useState({ type: 'call', price: 0 });
  const [companyExchange, setCompanyExchange] = useState('');
  const [tradingSymbolFno, setTradingSymbolFno] = useState('');
  const [strikePrice, setStrikePrice] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { instrumentToken, exchange } = useParams();
  const [lotSize, setLotSize] = useState(null);
  const { socketData } = useWebSocketData();
  const [socketStrikePrice, setSocketStrikePrice] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [limit, setLimit] = useState("");
  const [orderType, setOrderType] = useState("Market");
  const [availableAmount, setAvailableAmount] = useState();
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [isBuyInProgress, setIsBuyInProgress] = useState(false);
  const [isSellInProgress, setIsSellInProgress] = useState(false);
  const [socketOptionsData, setSocketOptionsData] = useState({ CE: [], PE: [] });

  const currentTheme = theme === 'dark' ? {
    backgroundColor: '#181818', 
    selectedDateBackground: '#007BFF',
    buttonBackground: '#444',
    buttonText: '#fff',
    selectedDateTextColor: '#ffffff',
    contentBackground: '#2c2c2c',
    textColor: '#fff',
    skeletonColor: '#3a3a3a'
  } : {
    backgroundColor: '#ffffff',
    selectedDateBackground: '#007BFF',
    buttonBackground: '#f0f0f0',
    buttonText: '#000',
    selectedDateTextColor: '#ffffff',
    contentBackground: '#f5f5f5',
    textColor: '#000',
    skeletonColor: '#e0e0e0'
  };

  useEffect(() => {
     const fetchCompanyDetails = async () => {
      try {
        const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
        const response = await axios.get(apiUrl, {
          headers: {
            "instrument-token": instrumentToken,
            "market-type": exchange,
          },
        });

        setStrikePrice(response.data.last_price);
        fetchExpiryDates(response.data.tradingsymbol);
      } catch (error) {
        console.error("Error fetching company details", error);
      }
    };

    const fetchExpiryDates = async (tradingSymbol) => {
      setLoading(true);
      try {
        let symbolToSend = tradingSymbol;
        if (tradingSymbol === 'NIFTY 50') {
          symbolToSend = 'NIFTY';
        } else if (tradingSymbol === 'NIFTY BANK') {
          symbolToSend = 'BANKNIFTY';
        }
        const response = await fetch('https://backend.smartcapital.co.in/stock/getExpiryDates', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'trade-symbol': symbolToSend,
          },
        });
        const data = await response.json();
        if (data.status === 'success') {
          setExpiryDates(data.expiryDates.slice(0, 5));
          setSelectedDate(data.expiryDates[0]);
        } else {
          console.error('Failed to fetch expiry dates');
        }
      } catch (error) {
        console.error('Error fetching expiry dates:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAvailableAmount = async () => {
      try {
        const userToken = Cookies.get("userToken");
        const userId = Cookies.get("userId");

        const apiUrl = `${config.apiBaseUrl}/user/getAvailableBalance`;
        const response = await axios.get(apiUrl, {
          headers: {
            "user-id": userId,
            "x-access-token": userToken,
          },
        });

        if (response.status === 200) {
          setAvailableAmount(response.data.availableBalance || 0);
        } else {
          setAvailableAmount(null);
        }
      } catch (error) {
        setAvailableAmount(null);
      }
    };

    Promise.all([fetchCompanyDetails(), fetchAvailableAmount()]).then(() => {
      setApiDataLoaded(true); 
    });

  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
        const response = await axios.get(apiUrl, {
          headers: {
            "instrument-token": instrumentToken,
            "market-type": exchange,
          },
        });

        setStrikePrice(response.data.last_price);
        fetchOptionData(response.data.tradingsymbol);
      } catch (error) {
        console.error("Error fetching company details", error);
      }
    };

    const fetchOptionData = async (tradingSymbol) => {
      setLoading(true);
      try {
        let symbolToSend = tradingSymbol;
        if (tradingSymbol === 'NIFTY 50') {
          symbolToSend = 'NIFTY';
        } else if (tradingSymbol === 'NIFTY BANK') {
          symbolToSend = 'BANKNIFTY';
        }

        const response = await fetch('https://backend.smartcapital.co.in/stock/getOptionChain', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'filter-date': selectedDate,
            'trade-symbol': symbolToSend,
          },
        });
        const data = await response.json();
        if (data.status === 'success' && data.data) {
          const CE = data.data.filter((option) => option.instrument_type === 'CE');
          const PE = data.data.filter((option) => option.instrument_type === 'PE');

          setOptionsData({ 
            CE: CE.sort((a, b) => a.strike - b.strike),
            PE: PE.sort((a, b) => a.strike - b.strike)
          });
        } else {
          console.error('Failed to fetch option data');
        }
      } catch (error) {
        console.error('Error fetching option data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (apiDataLoaded) {
      fetchCompanyDetails();
    }
  }, [selectedDate, apiDataLoaded, instrumentToken, exchange]);

   const handlePriceClick = (type, price, strike, instrumentToken, tradingsymbol,exchange) => {
    setClickPrice({ type, price });
    setSelectedInstrumentToken(instrumentToken);
    setCompanyExchange(exchange);
    setTradingSymbolFno(tradingsymbol);
    setIsFormOpen(true);

    const selectedOption = optionsData[type === 'call' ? 'CE' : 'PE'].find(option => option.strike === strike);
    
    if (selectedOption) {
      setLotSize(selectedOption.lot_size);
    }
  };

  useEffect(() => {
    if (socketData && Object.keys(socketData).length > 0) {
      const strikeToken = instrumentToken;
      if (strikeToken && socketData[strikeToken]) {
        const strikeInfo = socketData[strikeToken];
        setSocketStrikePrice(strikeInfo.last_price);
      }

      const updatedOptionsData = { ...optionsData };

      ['CE', 'PE'].forEach((type) => {
        updatedOptionsData[type].forEach((option, index) => {
          const socketInfo = socketData[option.instrument_token];

          if (socketInfo) {
            updatedOptionsData[type][index].last_price = socketInfo.last_price;
            updatedOptionsData[type][index].net_change = socketInfo.net_change;
          }
        });
      });

      setSocketOptionsData(updatedOptionsData);

      if (clickPrice.type === "call" || clickPrice.type === "put") {
        const updatedPrice = socketData[selectedInstrumentToken]?.last_price || clickPrice.price;
        setClickPrice((prev) => ({ ...prev, price: updatedPrice }));
      }
    }
  }, [socketData, optionsData, clickPrice, selectedInstrumentToken]);

  const handleBack = () => {
    setIsFormOpen(false);
  };

  const getCurrentStrikeRange = (optionsData, currentStrikePrice) => {
    const callStrikes = optionsData.CE.map((call) => call.strike);
    const putStrikes = optionsData.PE.map((put) => put.strike);

    const strikePriceList = Array.from(new Set([...callStrikes, ...putStrikes])).sort((a, b) => a - b);

    for (let i = 0; i < strikePriceList.length - 1; i++) {
      const min = strikePriceList[i];
      const max = strikePriceList[i + 1];

      if (currentStrikePrice >= min && currentStrikePrice <= max) {
        return { min, max, aboveIndex: i, belowIndex: i + 1 };
      }
    }

    return null; 
  };

  const handleBuy = async () => {
        const accessToken = Cookies.get("accessToken");
    
    setIsBuyInProgress(true);
    try {
      const latestPrice = orderType === "Market" ? clickPrice?.price : limit;
      const requiredAmount =  latestPrice;
      const quantitywithlot = quantity * lotSize;

      if (availableAmount < requiredAmount) {
        Swal.fire({
          title: 'Insufficient Balance',
          text: 'Please add funds to proceed with the purchase.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return; 
      }

      if (quantity <= 0) {
        Swal.fire({
          title: 'Error!',
          text: 'Quantity must be greater than 0 to place a buy order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      

      const orderData = {
        userId: userId,
        instrument_token: selectedInstrumentToken,
        symbol: tradingSymbolFno,
        orderType: orderType.toLowerCase(),
        quantity: quantitywithlot,
        price: requiredAmount,  
        tradeType: 'option',
        stockType : companyExchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/buy`, orderData, {
        headers: {
          'x-access-token': userToken,
          'kite-token': accessToken

        },
      });

      if (response && response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message || 'Order placed successfully!',  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Unexpected status code: ${response?.status || 'No status'} `, 
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Insufficient Stock!',
            text: 'You do not have enough stock to complete this transaction.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'An unexpected error occurred. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Runtime Error!',
          text: error.message || 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }finally{
      setIsBuyInProgress(false);
    }
  };


  const handleSell = async () => {
    const accessToken = Cookies.get("accessToken");
    setIsSellInProgress(true);
    try {
      const latestPrice = orderType === "Market" ? clickPrice?.price : limit;
      const requiredAmount =  latestPrice;
      const quantitywithlot = quantity * lotSize;

      if (quantity <= 0) {
        Swal.fire({
          title: 'Error!',
          text: 'Quantity must be greater than 0 to place a sell order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      
      const orderData = {
        userId: userId,
        instrument_token: selectedInstrumentToken,
        symbol: tradingSymbolFno,
        orderType: orderType.toLowerCase(),
        quantity: quantitywithlot,
        price: requiredAmount,  
        tradeType: 'option',
        stockType : companyExchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/sell`, orderData, {
        headers: {
          'x-access-token': userToken,
          "kite-token":accessToken
        },
      });

      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message || 'Order placed successfully!',  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Unexpected status code: ${response?.status || 'No status'}`, 
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Insufficient Stock!',
            text: 'You do not have enough stock to complete this transaction.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'An unexpected error occurred. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Runtime Error!',
          text: error.message || 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }finally{
      setIsSellInProgress(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: currentTheme.backgroundColor, color: currentTheme.textColor, padding: 2, maxHeight: '500px' }}>
      {!isFormOpen && (
        <Box display="flex" gap={1} mb={2} flexWrap="nowrap" position="sticky" top={0} bgcolor={currentTheme.backgroundColor} zIndex={1} height={40}>
          {expiryDates.map((date) => {
            const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
            return (
              <Button
                key={date}
                variant={date === selectedDate ? 'contained' : 'outlined'}
                size="small"
                onClick={() => setSelectedDate(date)}
                sx={{
                  fontSize: '10px',
                  padding: '4px 8px',
                  minWidth: '60px',
                  flexGrow: 1,
                  backgroundColor: date === selectedDate ? currentTheme.selectedDateBackground : currentTheme.buttonBackground,
                  color: date === selectedDate ? currentTheme.selectedDateTextColor : currentTheme.buttonText,
                  '&:hover': {
                    backgroundColor: currentTheme.selectedDateBackground,
                  },
                }}
              >
                {formattedDate}
              </Button>
            );
          })}
        </Box>
      )}
      {isFormOpen ? (
          <Box >
            <CardContent
              style={{
                color: theme === "dark" ? "#fff" : "#000",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
                minHeight:'450px',
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    startIcon={<ArrowBack />}
                    onClick={handleBack}
                    sx={{ color: theme === "dark" ? "#fff" : "#000", padding: "5px", minWidth: "unset", cursor:'pointer' }}
                  />
                  <Typography variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '220px'}}>
                    {tradingSymbolFno}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "18px", fontWeight: "bold", paddingLeft: "20px" }}>
                  ₹{clickPrice?.price}
                </Box>
              </Box>

              {/* Quantity Input */}
              <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"}>
                    Quantity
                  </Typography>
                  <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"} align="right">
                    1 lot x {lotSize}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                  <TextField
                    type="text"  
                    value={quantity === "" ? "" : quantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || /^[0-9]*$/.test(value)) {
                        setQuantity(value);
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    style={{
                      borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                      backgroundColor: theme === "dark" ? "#333" : "transparent",
                      height: "40px",
                    }}
                    InputProps={{
                      style: {
                        color: theme === "dark" ? "white" : "black",height: "100%",},
                    }}
                    InputLabelProps={{
                      style: {color: theme === "dark" ? "white" : "black",},
                    }}
                  />
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "8px",
                      color: theme === "dark" ? "white" : "black",
                      borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                      height: "40px", 
                      minWidth: "40px",
                      padding: "0 10px", 
                      }}
                    onClick={() => setQuantity(quantity === "" ? 1 : Number(quantity) + 1)}
                    >
                    +
                  </Button>
                  </Box>
              </Box>

              <Box mt={4}>
              <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"}>
                Price
              </Typography>
              <TextField
                value={orderType === "Limit" ? (limit || clickPrice?.price ) :clickPrice?.price} 
                variant="outlined"
                fullWidth
                onChange={(e) => orderType === "Limit" && setLimit(e.target.value)}  
                disabled={orderType === "Market"}  
                style={{
                  borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                  backgroundColor: theme === "dark" ? "#333" : "transparent", 
                  height: "40px",
                }}
                InputProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                    height: "40px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                    height: "100%",
                  },
                }}
              />
            </Box>

          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Market" ? "#1976d2" : "transparent",
                    color: orderType === "Market" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Market" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Market")}
                >
                  Market
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Limit" ? "#1976d2" : "transparent",
                    color: orderType === "Limit" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Limit" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Limit")}
                >
                  Limit
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box mt={4}>
            <Box display="flex" justifyContent="space-between" fontSize="small" mt={2}>
              <Typography color={theme === "dark" ? "white" : "textSecondary"}>Available Amount:</Typography>
              <Typography>₹{availableAmount ? availableAmount.toFixed(2) : "0"}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" fontSize="small" mt={2}>
              <Typography color={theme === "dark" ? "white" : "textSecondary"}>Required:</Typography>
              <Typography>
                ₹{
                  (quantity * lotSize * (orderType === "Market" ? clickPrice?.price : limit)).toFixed(2)
                }
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              sx={{
                width: '45%',
                marginRight: 1,
                backgroundColor: 'green',
                color: 'white',
                opacity: isBuyInProgress ? 0.5 : 1, 
                pointerEvents: isBuyInProgress ? 'none' : 'auto',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
              onClick={handleBuy}
              disabled={isBuyInProgress} 
            >
              Buy
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '45%',
                backgroundColor: 'red',
                color: 'white',
                opacity: isSellInProgress ? 0.5 : 1,
                pointerEvents: isSellInProgress ? 'none' : 'auto',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
              onClick={handleSell}
              disabled={isSellInProgress} 
            >
              Sell
            </Button>
          </Box>
          </CardContent> 
        </Box>
      ) : (
        <Box sx={{ overflowY: 'auto', maxHeight: '430px', padding: '10px', minHeight: '430px' }}>
          {loading || !apiDataLoaded ? (
            <Box>
              <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1, backgroundColor: currentTheme.skeletonColor }} />
              <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1, backgroundColor: currentTheme.skeletonColor }} />
              <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1, backgroundColor: currentTheme.skeletonColor }} />
            </Box>
          ) : (
            <>
              <Box display="flex" fontSize="10px" fontWeight="bold" mb={1}>
                <Box sx={{ width: '33%', textAlign: 'left' }}>Call Price</Box>
                <Box sx={{ width: '33%', textAlign: 'center' }}>Strike Price</Box>
                <Box sx={{ width: '33%', textAlign: 'right' }}>Put Price</Box>
              </Box>
              <Box>
               {(socketOptionsData?.CE?.length > 0 || optionsData.CE.length > 0) || (socketOptionsData?.PE?.length > 0 || optionsData.PE.length > 0) ? (
                  (socketOptionsData?.CE || optionsData.CE).map((callOption, index) => {
                    const correspondingPut = (socketOptionsData?.PE || optionsData.PE).find((putOption) => putOption.strike === callOption.strike);
                    const currentRange = getCurrentStrikeRange(socketOptionsData || optionsData, strikePrice);
                    return (
                      <Box key={index} display="flex" fontSize="10px" py={0.5}>
                        <Box
                          sx={{ width: '33%', cursor: 'pointer' }}
                          onClick={() =>
                            handlePriceClick(
                              'call',
                              callOption.last_price,
                              callOption.strike,
                              callOption.instrument_token,
                              callOption.tradingsymbol,
                              callOption.exchange
                            )
                          }
                        >
                          <Typography variant="body2">₹{callOption.last_price}</Typography>
                          <Typography variant="caption" color={callOption.net_change < 0 ? 'error.main' : 'success.main'}>
                            {typeof callOption.net_change === 'number' ? callOption.net_change.toFixed(2) : 'N/A'}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="body1" fontSize="16px" fontWeight="bold">
                            {callOption.strike}
                          </Typography>
                          {currentRange && index === currentRange.aboveIndex && (
                            <Box
                              sx={{
                                marginTop: '10px',
                                padding: '5px 10px',
                                backgroundColor: '#1e90ff',
                                borderRadius: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '80%',
                                height: '15px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'relative',
                                '@media (max-width: 1250px)': {
                                  width: '80%',
                                },
                                '&::before': {
                                  content: '""',
                                  position: 'absolute',
                                  left: '-45%',
                                  top: '50%',
                                  width: '45%',
                                  height: '2px',
                                  backgroundColor: '#1e90ff',
                                  transform: 'translateY(-50%)',
                                },
                                '&::after': {
                                  content: '""',
                                  position: 'absolute',
                                  right: '-45%',
                                  top: '50%',
                                  width: '45%',
                                  height: '2px',
                                  backgroundColor: '#1e90ff',
                                  transform: 'translateY(-50%)',
                                },
                              }}
                            >
                              <Typography variant="body2" sx={{ color: 'white', fontSize: '14px' }}>
                                ₹{socketStrikePrice || strikePrice}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{ width: '33%', cursor: 'pointer', textAlign: 'right', marginRight: '5px' }}
                          onClick={() =>
                            handlePriceClick(
                              'put',
                              correspondingPut ? correspondingPut.last_price : 0,
                              callOption.strike,
                              correspondingPut ? correspondingPut.instrument_token : null,
                              correspondingPut ? correspondingPut.tradingsymbol : '',
                              correspondingPut ? correspondingPut.exchange : ''
                            )
                          }
                        >
                          {correspondingPut ? (
                            <Typography variant="body2">₹{correspondingPut.last_price}</Typography>
                          ) : (
                            <Typography variant="body2">N/A</Typography>
                          )}
                          <Typography variant="caption" color={correspondingPut?.net_change < 0 ? 'error.main' : 'success.main'}>
                            {typeof correspondingPut?.net_change === 'number' ? correspondingPut.net_change.toFixed(2) : 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                ) : null}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OptionDisplay;
